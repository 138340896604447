export const phoneNumbers = {
    nav: '+1 (860) 453-5276',
    'phoneNumberV0': '1-860-452-3497',
    'phoneNumberV1': '1-860-452-3976',
    'supportPhoneNumber': '1-860-452-3872'
}

export const saatvaContactInfo = {
    phoneNumberV0: '1-860-362-2641',
    phoneNumberV1: '1-860-546-3250'
}

export const urls = {
    'quiz': 'https://www.saatva.com/mattress-quiz'
}
